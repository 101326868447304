<template>
  <div :id="id"></div>
</template>
<script>
// import echarts from 'echarts'

export default {
  props: ['id', 'pielist', 'title'],

  data () {
    return {
      charts: '',
      YDATA: []
    }
  },
  watch: {
    pielist: {
      deep: true,
      immediate: true,
      handler (newvalue, lodvalue) {
        this.$nextTick(() => {
          this.pielist = newvalue
          this.initChart(this.id)
        })
      }
    }

  },
  mounted () {
    this.$nextTick(() => {
      this.initChart(this.id)
    })
  },
  created () { },
  methods: {
    initChart (id) {
      var colors = [
        '#5e81ec',
        '#ffc855',
        '#98e79b',
        '#00d695',
        '#00b29a',
        '#5470c6',
        '#91cc75',
        '#fac858',
        '#ee6666',
        '#73c0de',
        '#3ba272',
        '#fc8452',
        '#9a60b4',
        '#ea7ccc'
      ]
      var data = this.pielist
      this.charts = this.$echarts.init(document.getElementById(id))
      window.onresize = this.charts.resize
      this.charts.setOption({
        backgroundColor: 'transparent',
        title: {
          text: this.title,
          left: 'center',
          top: 'center',
          // x: 'left',
          // y: 'center',
          textStyle: {
            fontSize: 18
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        color: colors,
        calculable: true,
        series: [
          {
            type: 'pie',
            radius: ['20%', '45%'],
            center: ['50%', '50%'],
            roseType: 'radius',
            label: {
              show: true,
              color: 'inherit',
              position: 'outside',
              fontSize: 14,
              formatter: '{b} : {c} '
            },
            labelLine: {
              length: 1,
              length2: 20,
              smooth: true
            },
            data: data
          }
        ]
      })
    }
  }
}
</script>
