<template>
  <div class="diagrambox">

    <div class="diagrambox-topbox">
      <!-- <el-button class="btn" type="primary" icon="el-icon-arrow-left" @click="goback()">返回</el-button> -->
      <p>告警时间:</p>
      <el-date-picker v-model="Orderobj.startTime" type="date" value-format="yyyy-MM-dd"
        placeholder="选择日期" @change="settime($event)">
      </el-date-picker>
      <p>告警类型:</p>

      <el-select v-model="Orderobj.status" placeholder="请选择" :popper-append-to-body="false"
        @change="shutDown($event)" clearable>
        <el-option v-for="item in statuslist" :key="item.value" :label="item.value"
          :value="item.value">
        </el-option>
      </el-select>
      <p>处理结果:</p>
      <el-select v-model="Orderobj.result" placeholder="请选择" :popper-append-to-body="false"
        clearable @change="Results($event)">
        <el-option v-for="item in warninglist" :key="item.value" :label="item.value"
          :value="item.value">
        </el-option>
      </el-select>
      <p>告警区域:</p>
      <el-select v-model="Orderobj.radarCoordinates" placeholder="请选择" :popper-append-to-body="false"
        clearable >
        <el-option v-for="item in namelist" :key="item.value" :label="item.value"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" @click="pagelist()">搜索</el-button>
      <el-button type="primary" icon="el-icon-refresh" @click="resetbtn()">重置</el-button>
      <el-button type="primary" icon="el-icon-download" @click="exportbtn()">导出</el-button>
      <!-- <el-button type="primary" @click="gotopolice()">设备报警信息</el-button> -->
    </div>
    <div class="diagrambox-center">
      <div class="diagrambox-center-map">
        <div class="tabbox">
          <el-table :data="tableData" style="width: 100%;color:#BFDAFD"
            :header-cell-style="{background:'#4882C0', color:'#fff'}"
            :row-class-name="tableRowClassName" size="small">
            <!-- :preview-src-list="scope.row.srcList"
            fit="contain" -->
            <el-table-column prop="img" label="告警图片">
              <template slot-scope="scope">
                <img style="width: 100px; height: 60px"
                  :src="'http://183.94.86.130:6548/' + scope.row.img" />
                <!-- <img style="width: 100px; height: 60px" v-if="scope.row.location === 1"
                  :src="'http://183.94.86.130:8090/' + scope.row.img" />
                <img style="width: 100px; height: 60px" v-else
                  :src="'http://183.94.86.130:6548/' + scope.row.img" /> -->
              </template>
            </el-table-column>
            <el-table-column prop="szName" label="告警区域">
              <template slot-scope="scope">
                <span>{{scope.row.radarCoordinates + ' - ' + scope.row.szName}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="data" label="告警时间" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="result" label="处理结果">
            </el-table-column>
            <el-table-column prop="updataTime" label="已处理时间" :show-overflow-tooltip='true'>
            </el-table-column>
            <el-table-column prop="principal" label="负责人">
            </el-table-column>
            <el-table-column prop="status" label="告警类型">
              <template slot-scope="scope">
                <span v-if="scope.row.status === '未处理'"
                  style="color:#FFF119">{{scope.row.status}}</span>
                <span v-else>{{scope.row.status}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button v-if="scope.row.status === '未处理'"
                  @click="handleClick(scope.row,scope.$index)" type="primary" size="small">处理
                </el-button>
                <el-button v-else @click="handleClick(scope.row,scope.$index)" type="primary"
                  size="small">详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination background @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="Orderobj.pagSum" :page-sizes="[10]"
            :page-size="Orderobj.pageSize" layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
      <div class="diagrambox-center-list">
        <div class="modebox">
          <TitleBox :titlename="'本月数量统计'" />
          <div class="timebox">
            <!-- <p>「2022年5月17日-2022年6月16日」</p> -->
          </div>
          <div class="detailsbox">
            <div class="echarts">
              <Echartspie id="electricid" v-if="flagFX" class="echarbiongt" :pielist="pielist"
                :title="title" />
            </div>
            <div class="imgbox">
              <div class="imgmodebox" v-for="(item , index) in pielist" :key="index">
                <img v-if="index === 0" src="../../assets/homeimg/22.png" alt="">
                <img v-if="index === 1" src="../../assets/homeimg/13.png" alt="">
                <img v-if="index === 2" src="../../assets/homeimg/23.png" alt="">
                <div class="textbox">
                  <p>{{item.name}}</p>
                  <p>{{item.value}}</p>
                </div>
              </div>
              <!-- <div class="imgmodebox">
                <img src="../../assets/homeimg/22.png" alt="">
                <div class="textbox">
                  <p>{{pielist[0].name}}</p>
                  <p>{{pielist[0].value}}</p>
                </div>
              </div>
              <div class="imgmodebox">
                <img src="../../assets/homeimg/21.png" alt="">
                <div class="textbox">
                  <p>{{pielist[1].name}}</p>
                  <p>{{pielist[1].value}}</p>
                </div>
              </div>
              <div class="imgmodebox">
                <img src="../../assets/homeimg/13.png" alt="">
                <div class="textbox">
                  <p>{{pielist[2].name}}</p>
                  <p>{{pielist[2].value}}</p>
                </div>
              </div> -->

            </div>
          </div>

        </div>
        <div class="modebox">
          <TitleBox :titlename="'本月处理结果统计'" />
          <div class="timebox">
            <!-- <p>「2022年5月17日-2022年6月16日」</p> -->
          </div>
          <div class="detailsbox">
            <div class="echarts">
              <Echartspie id="Echartspieid" v-if="flagFX" class="echarbiongt" :pielist="disposelist"
                :title="title" />
            </div>
            <div class="imgbox">
              <div class="imgmodebox" v-for="(item , index) in disposelist" :key="index">
                <img v-if="index === 0" src="../../assets/homeimg/15.png" alt="">
                <img v-if="index === 1" src="../../assets/homeimg/16.png" alt="">
                <img v-if="index === 2" src="../../assets/homeimg/17.png" alt="">
                <img v-if="index === 3" src="../../assets/homeimg/18.png" alt="">
                <div class="textbox">
                  <p>{{item.name}}</p>
                  <p>{{item.value}}</p>
                </div>
              </div>
              <!-- <div class="imgmodebox">
                <img src="../../assets/homeimg/16.png" alt="">
                <div class="textbox">
                  <p>{{disposelist[1].name}}</p>
                  <p>{{disposelist[1].value}}</p>
                </div>
              </div>
              <div class="imgmodebox">
                <img src="../../assets/homeimg/17.png" alt="">
                <div class="textbox">
                  <p>{{disposelist[2].name}}</p>
                  <p>{{disposelist[2].value}}</p>
                </div>
              </div>
              <div class="imgmodebox">
                <img src="../../assets/homeimg/18.png" alt="">
                <div class="textbox">
                  <p>{{disposelist[3].name}}</p>
                  <p>{{disposelist[3].value}}</p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="modebox">
          <TitleBox :titlename="'本年告警趋势'" />
          <div class="timebox">
            <!-- <p>「2022年」</p> -->
          </div>
          <div class="curvebox">
            <Echartscurve id="Echartscurveid" v-if="flagFX" class="echarbiongt"
              :curvelist="curvelist" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import api from '../visualization/api'
import TitleBox from '../visualization/components/title/index.vue'
import Echartspie from '../visualization/components/echarts/echartspie.vue'
import { exportExcel } from '../utils/excel'
import Echartscurve from '../visualization/components/echarts/echartscurve.vue'

export default {
  props: {},
  components: {
    TitleBox,
    Echartspie,
    Echartscurve

  },
  data () {
    return {
      total: 0, // 总条数
      value: '',
      tableData: [],
      flagFX: true,
      pielist: [
        {
          data: null,
          name: '疑似人员',
          value: '1.10'
        },
        { data: null, name: '疑似船只', value: '36.63' },
        { data: null, name: '疑似车辆', value: '32.97' }
      ],
      disposelist: [
      ],
      title: '',
      curvelist: {

      },
      Orderobj: {
        result: null, // 处理结果
        radarCoordinates: '', // 告警区域
        status: null, // 处理状态
        pagSum: 1, // 页码
        pageSize: 10, // 页大小
        startTime: '', // 开始时间
        endTime: '' // 结束时间
      },
      warninglist: [
        {
          value: '口头警告'
        },
        // {
        //   value: '行政拘留'
        // },
        {
          value: '行政处罚'
        },
        {
          value: '其他'
        }
      ],
      namelist: [
        {
          value: '牌洲'
        },
        {
          value: '潘湾'
        },
        {
          value: '老湾'
        },
        {
          value: '河道局'
        },
        {
          value: '螺山'
        }
      ],
      statuslist: [
        {
          value: '未处理'
        },
        {
          value: '已下发工单'
        },
        {
          value: '已处理'
        },
        {
          value: '误报'
        }
      ],
      passpagSum: null
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    shutDown (val) {
      if (val === '') {
        this.Orderobj.status = null
        this.Orderobj.result = null
      }
    },
    Results (val) {
      if (val === '') {
        this.Orderobj.status = null
        this.Orderobj.result = null
      }
    },

    settime (val) {
      // console.log(val)
      if (val != null) {
        this.Orderobj.startTime = val + ' 00:00:00'
        this.Orderobj.endTime = val + ' 23:59:59'
      } else {
        this.Orderobj.startTime = ''
        this.Orderobj.endTime = ''
      }
    },
    pagelist () {
      this.Orderobj.pagSum = 1
      this.pageWarningWorkOrder()
    },
    gotopolice () {
      this.$router.push({ name: 'police' })
    },
    goback () {
      this.$router.go(-1)
    },
    footerbtn (index) {
      this.footerAction = index
    },
    handleClick (row, index) {
      // console.log(row, index, 'row')
      this.toCalculate(index)
      this.$store.commit('zsqy/setOrderobj', this.Orderobj)
      this.$router.push({ name: 'warningdetails', params: { id: row.id, pagSum: this.passpagSum } })
    },
    toCalculate (index) {
      var num = this.Orderobj.pagSum * 10
      var size = num / 5
      if (index + 1 > 5) {
        this.passpagSum = size
      } else {
        this.passpagSum = size - 1
      }
    },
    tableRowClassName ({ row, rowIndex }) {
      return 'success-row'
    },
    // 分页
    handleSizeChange (val) {
      this.Orderobj.pageSize = val
      this.pageWarningWorkOrder()
    },
    // 切换分页
    handleCurrentChange (val) {
      this.Orderobj.pagSum = val
      this.pageWarningWorkOrder()
    },
    pageWarningWorkOrder () { // 本地接口
      axios.post(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findPage', this.Orderobj,
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.tableData = res.data.data.records
          this.total = res.data.data.total
        })
    },
    pagecurve () { // 本地接口
      axios.get(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/findDiagram',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          this.curvelist = res.data.data
        })
    },
    exportbtn () { // 本地接口
      const obj = {
        list: []
      }
      axios.post(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/exportExcel', obj,
        { headers: { database: '11NatureReserves' }, responseType: 'blob' }
      )
        .then((res) => {
          const fileName = '告警工单列表.xls'
          exportExcel(res.data, fileName)
        })
    },
    pagepie () { // 本地接口
      axios.get(this.$constant.zsqyUrl + '/zsqy/Radarworkorder/yearData',
        { headers: { database: '11NatureReserves' } }
      )
        .then((res) => {
          // console.log(res.data.data, '--------')
          this.pielist[0].value = res.data.data.SUSPECTEDPERSONNEL
          this.pielist[1].value = res.data.data.SUSPECTEDVEHICLE
          this.pielist[2].value = res.data.data.SUSPECTEDVESSEL
        })
    },
    pagedispose () {
      api.resultStatisticsPage().then(res => {
        // console.log(res, '===')
        this.disposelist = res.data.disposelist
        // this.environmentlist = res.data
      })
    },
    resetbtn () {
      this.Orderobj = {
        result: null,
        radarCoordinates: '',
        status: null,
        pagSum: 1,
        pageSize: 10,
        startTime: '',
        endTime: ''
      }
      this.pageWarningWorkOrder()
    }
  },
  created () {
    this.pageWarningWorkOrder()
    this.pagecurve()
    this.pagepie()
    this.pagedispose()
    document.title = '预警工单'
  }
}
</script>
<style>
.el-table .success-row {
  background: transparent !important;
}
/*最外层透明*/
.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}
/* 表格内背景颜色 */
.el-table th,
.el-table tr,
.el-table td {
  background-color: transparent;
}
</style>
<style lang="less" scoped>
.diagrambox {
  width: 100%;
  height: 100%;
  background: url('../../assets/homeimg/bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  .echarbiongt {
    width: 100%;
    height: 100%;
  }
  &-topbox {
    width: 96%;
    height: 40px;
    margin: 100px 0 1% 2%;
    // margin-left: 2%;
    // margin-bottom: 1%;
    display: flex;
    align-items: center;
    /deep/.el-input__inner {
      background-color: #5376a1;
      border: none;
      color: #bfdafd;
      font-size: 12px;
      border-radius: 0px;
    }
    // //item选项的hover样式
    // /deep/ .el-select-dropdown__item.hover,
    // /deep/ .el-select-dropdown__item:hover {
    //   color: #409eff;
    // }
    // /deep/ .el-select-dropdown {
    //   background-color: #08164d;
    //   margin: 0px;
    //   border: none;
    //   border-radius: 0px;
    // }
    // /deep/ .el-popper .popper__arrow,
    // .el-popper .popper__arrow::after {
    //   display: none;
    // }
    // /deep/ .el-select-dropdown__item.hover,
    // /deep/ .el-select-dropdown__item:hover {
    //   color: #409eff;
    // }

    p {
      margin: 0 15px;
      color: #fff;
      font-size: 16px;
      font-family: SourceHanSansCN;
      font-weight: 400;
    }
    .el-button {
      margin-left: 15px;
      margin-right: 15px;
    }
    .el-input {
      width: 180px;
    }
  }
  &-center {
    width: 96%;
    height: 75%;
    background: url('../../assets/homeimg/bb.png') no-repeat;
    background-size: 100% 100%;
    margin-left: 2%;
    overflow: hidden;
    /deep/.el-table--border:after,
    .el-table--group:after,
    .el-table:before {
      background-color: transparent;
    } //el table表单最下面下边框颜色
    /deep/.el-table td,
    .el-table th.is-leaf {
      border-bottom: 1px solid transparent !important;
      text-align: center;
    } //el table表单下边框颜色
    /deep/ .el-table tbody tr:hover > td {
      background-color: rgba(79, 119, 163, 0.4);
    } //鼠标移入表单背景颜色
    /deep/.el-table th.is-leaf {
      border-bottom: 1px solid #133e47;
      color: #fff;
      text-align: center;
    } //表头下边框颜色
    /deep/.el-table__cell .gutter {
      width: 0 !important;
    }

    display: flex;
    &-map {
      width: 60%;
      height: 90%;
      margin: 2% 0 0 1%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
      .tabbox {
        width: 100%;
        height: 90%;
        overflow-y: auto;
        border-bottom: 1px solid rgba(98, 145, 200, 0.6);
      }
      .paging {
        color: #fff !important;
        position: absolute;
        right: 2%;
        bottom: 1%;

        /deep/.el-input__inner {
          background: transparent;
          border: 1px solid #abdaff;
          color: #fff;
        } //input背景颜色
        /deep/.btn-prev {
          background: transparent;
          color: #fff;
        } //左箭头按钮背景
        /deep/.btn-next {
          background: transparent;
          color: #fff;
        } //右箭头按钮背景
        /deep/.number {
          background: transparent;
          color: #fff;
        } //未被选中的页码背景
        /deep/.active {
          background: #0090ff !important;
          color: #fff;
        } //被选中的页码背景
        /deep/.el-pagination.is-background .el-pager li {
          background: transparent;
          color: #fff;
        } //被折叠的页码背景
        /deep/.el-pagination__total {
          color: #fff;
        } //总条数字体颜色
        /deep/.el-pagination__jump {
          color: #fff;
        } //前往第几页数字体颜色
      }
      /* 设置滚动条的样式 */
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background: rgba(79, 119, 163, 0.4);
      }
      /* 滚动条滑块 */
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: RGBA(1, 137, 219, 1);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }
    }
    &-list {
      width: 37%;
      height: 100%;
      margin-left: 1.5%;
      // background: red;
      overflow: hidden;
      .modebox {
        margin-top: 3%;
        width: 100%;
        height: 30%;
        // background: red;
        position: relative;
        .timebox {
          position: absolute;
          top: 5%;
          right: 5%;
          font-size: 12px;
          font-family: SourceHanSansCN;
          font-weight: bold;
          color: #8cf0ff;
        }
        .detailsbox {
          width: 100%;
          height: 90%;
          display: flex;
          justify-content: space-between;

          .echarts {
            width: 49%;
            // background: #409eff;
            height: 100%;
          }
          .imgbox {
            width: 49%;
            // background: #409eff;
            height: 100%;
            display: flex;
            // justify-content: space-around;
            flex-wrap: wrap;

            .imgmodebox {
              width: 48%;
              height: 48%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              margin: 1% 1%;
              background: rgba(109, 157, 210, 0.2);
              border: 1px solid #6189b4;
              .textbox {
                text-align: center;
                font-size: 14px;
                font-family: SourceHanSansCN;
                font-weight: 400;
                color: #bfdafd;
              }
            }
          }
        }
        .curvebox {
          width: 100%;
          height: 90%;
        }
      }
    }
  }
}
</style>
